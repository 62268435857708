<template>
  <div class="v-rate">
    <van-rate
      v-model="score"
      :count="count"
      :size="size"
      :readonly="readonly"
      :allow-half="allowHalf"
      :icon="icon"
      :void-icon="voidIcon"
      :gutter="gutter"
      @change="handleChange"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { Rate } from "vant";
Vue.use(Rate);
export default {
  name: "rate",
  components: {},
  props: {
    //图标总数
    count: {
      type: Number,
      default: 5,
    },
    //图标总数
    size: {
      type: [String, Number],
      default: 30,
    },
    //值
    value: {
      type: Number,
      default: 0,
    },
    //是否半星
    allowHalf: {
      type: Boolean,
      default: false,
    },
    //是否为只读状态
    readonly: {
      type: Boolean,
      default: false,
    },
    //未选中时的颜色
    voidColor: {
      type: String,
      default: "#eee",
    },
    //未选中时的图标名称或图片链接
    voidIcon: {
      type: String,
      default: require("./img/sorce.png"),
    },
    //	选中时的图标名称或图片链接
    icon: {
      type: String,
      default: require("./img/start.png"),
    },
    //选中时的颜色
    color: {
      type: String,
      default: "#ffd21e",
    },
    gutter: {
      type: [String, Number],
      default: 12,
    },
  },
  watch: {
    value(newVal) {
      this.score = newVal;
    },
    score(newVal) {
      console.log(newVal);
      this.$emit("input", newVal);
    },
  },

  data() {
    return {
      score: this.value,
    };
  },
  created() {},
  mounted() {},
  methods: {
    handleChange(value) {
      this.$emit("change", value);
    },
  },
};
</script>

<style scoped lang="less">
.v-rate {
  width: 100%;
}
</style>
