<template>
  <div class="rating">
    <div class="gary-start">
      <div class="icon" v-for="num in 5" :key="num">
        <img src="./img/garystart.png" alt="" />
      </div>
    </div>
    <div class="start" :style="{ width: (rate / 5) * 100 + '%' }">
      <div class="stars-selected">
        <div class="icon" v-for="num in 5" :key="num">
          <img src="./img/start.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "rating",
  components: {},
  props: {
    rate: {
      type: [String, Number],
      default: 3.5,
    },
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="less">
.rating {
  width: 100%;
  height: 100%;
  position: relative;
  .gary-start {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 32px;
      &:not(:last-child) {
        margin-right: 8px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  .start {
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    .stars-selected {
      display: flex;
      width: 206px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 34px;
      height: 32px;
      &:not(:last-child) {
        margin-right: 8px;
      }
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
</style>
