<template>
  <div class="service-detail">
    <div class="service-info">
      <div
        class="service-info-l"
        @click="handelClickImgView(detailParams.coverPicture)"
      >
        <img :src="detailParams.coverPicture" />
      </div>
      <div class="service-info-r">
        <div class="service-info-r-title">
          <span>{{ detailParams.title }}</span>
          <div class="detail-r-evaluate">
            <div
              v-if="detailParams.evaluateScore == 0"
              class="detail-r-evaluate-status"
            >
              未评价
            </div>
            <div class="rate" v-else>
              <rate :rate="detailParams.evaluateScore"></rate>
            </div>
          </div>
        </div>
        <div class="service-content">
          <div class="service-describe">
            {{ detailParams.subTitle }}
          </div>
          <div class="service-issuer">
            发布人：{{ detailParams.broadcaster }}
          </div>
        </div>
        <div class="service-status">
          <div class="time">{{ checkTime(detailParams.createTime) }}</div>
          <div class="type">发布类型：{{ detailParams.serviceTypeName }}</div>
        </div>
      </div>
    </div>
    <div class="service-introduce">
      <div class="service-introduce-info">
        <div class="common-title">子分类</div>
        <div class="service-introduce-content">
          {{ detailParams.serviceTypeLv2Name }}
        </div>
      </div>
      <div class="service-introduce-info">
        <div class="common-title">服务机构名称</div>
        <div class="service-introduce-content">{{ detailParams.orgName }}</div>
      </div>
      <div class="service-introduce-info">
        <div class="common-title">商家地址</div>
        <div
          class="service-introduce-content address"
          @click="
            goNavigation(detailParams, detailParams.lat, detailParams.lng)
          "
        >
          <span> {{ detailParams.address }}{{ detailParams.doorplate }} </span>
          <img src="./img/navigation.png" alt="" />
        </div>
      </div>
      <div class="service-introduce-info" @click="contact">
        <div class="common-title">联系方式</div>
        <div class="service-introduce-content" style="color: blue">
          {{ detailParams.contactMobile }}
        </div>
      </div>
      <div class="service-type">
        <div class="service-type-l">服务方式</div>
        <div class="service-type-l">{{ detailParams.serviceMode }}</div>
      </div>
      <div class="line"></div>
      <div class="service-introduce-info">
        <div class="common-title">服务介绍</div>
        <div class="service-introduce-content describe">
          <v-h5Mtml :content="detailParams.description"></v-h5Mtml>
        </div>
      </div>
      <div class="service-introduce-info" v-if="detailParams.contentPictures">
        <div class="common-title">内容图片</div>
        <div>
          <v-upload
            :showUpload="false"
            :deletable="false"
            class="oItem-b_upload"
            :imgUrl.sync="detailParams.contentPictures"
            :activityPicture.sync="detailParams.contentPictures"
            :maxCount="9"
            ref="load"
          ></v-upload>
        </div>
      </div>
    </div>
    <!-- 评价 -->
    <div class="service-evaluate">
      <div class="service-evaluate-head">
        <span>评价</span>
        <div @click="goEvaluate">评价</div>
      </div>
      <v-list
        :finished="finished"
        @onLoad="onLoad"
        :finishedText="finishedText"
        ref="list"
      >
        <div
          class="service-evaluate-list"
          v-for="(item, index) in evaluateList"
          :key="index"
        >
          <div class="evaluate-user-detail">
            <div class="user-avatar">
              <img
                :src="
                  item.evaluaterAvatar
                    ? item.evaluaterAvatar
                    : require('./img/user.png')
                "
                alt=""
              />
            </div>
            <div class="user-info">
              <div class="username">
                <div class="name">{{ item.evaluaterName }}</div>
                <div class="time">{{ item.evaluateTime }}</div>
              </div>
              <div class="rate">
                <div class="rating">
                  <c-rate readonly :allowHalf="true" v-model="item.score" />
                </div>
                <!-- <div class="status">满意</div> -->
              </div>
            </div>
          </div>
          <div class="evaluate-content">{{ item.commentContent }}</div>
          <div class="line"></div>
        </div>
      </v-list>
    </div>
    <!-- 评价弹窗 -->
    <div class="evaluate-dialog">
      <v-popup v-model="isShowDialog" height="100%" :closeable="false">
        <div @click.stop :class="['dialog-content', { active: isShowDialog }]">
          <div class="rate">
            <div>对商家的评价</div>
            <div class="rating">
              <c-rate v-model="score" @change="handleChange" />
            </div>
          </div>
          <div class="send-content">
            <v-input
              v-model="evaluateParams.commentContent"
              @focus="onFocus"
              type="textarea"
              placeholder="不输入默认好评"
              rows="2"
            ></v-input>
          </div>
          <div class="send-btn" @click="publishEvaluate">
            <div class="btn">发布</div>
          </div>
        </div>
      </v-popup>
    </div>
    <div class="dialog" @click.prevent="isShow = false" v-if="isShow">
      <div class="dialog-content" @click.stop>
        <div class="title">免责条款</div>
        <div class="text">
          1.1用户明确同意其使用“奉化丨家门口”小程序网络服务所存在的风险及后果将完全由用户本人承担，“奉化丨家门口”对此不承担任何责任。<br />
          1.2对于因不可抗力或“奉化丨家门口”不能控制的原因造成服务中断或其他缺陷，“奉化丨家门口”不承担任何责任，但将尽力减少因此给用户造成的影响。包括天灾、法律法规或政府指令的变更，因网络服务特性而特有的原因，例如小程序服务器环境主机或网络故障、计算机或互联网相关技术缺陷、互联网覆盖范围限制、计算机病毒、黑客攻击等因素，及其他合法范围内的不能预见、不能避免、不能克服的客观情况。<br />
          1.3“奉化丨家门口”小程序不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该外部链接指向的内容，也不承担任何责任。<br />
          1.4“奉化丨家门口”小程序可能会调用第三方系统或通过第三方支持用户的使用或访问，“奉化丨家门口”不保证通过第三方提供服务及内容的合法性、安全性、准确性、有效性及其他不确定的风险，由此引发的任何争议及损害，“奉化丨家门口”不承担任何责。<br />
          1.5“奉化丨家门口”不保证加盟企业提供的服务一定能满足用户的要求和期望，也不保证服务不会中断，对服务的合法性、有效性、及时性、安全性、准确性都不作保证。加盟企业向用户提供的产品或者服务的质量问题本身及其引发的任何损失，“奉化丨家门口”无需承担任何责任。“奉化丨家门口”仅提供用户和加盟企业的交往交流等服务，不雇佣员工，也不承揽相关的服务工作，对加盟企业服务人员及用户双方过错不承担任何责任。<br />
          1.6“奉化丨家门口”小程序有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，“奉化丨家门口”对用户和任何第三人均无需承担任何责任。<br />
          1.7、如用户的行为使“奉化丨家门口”遭受损失
          (包括自身的直接经济损失、对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失)，用户应赔偿“奉化丨家门口”的上述全部损失。如用户的行为使“奉化丨家门口”遭受第三人主张权利，“奉化丨家门口”可在对第三人承担金钱给付等义务后就全部损失向用户追偿。
        </div>
        <div class="footer" @click="confirm">我知道了</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ImagePreview } from "vant";
import rate from "./rating.vue";
import wx from "weixin-js-sdk";
import {
  initWxJsSdkUrl,
  getServiceInfoById,
  getServiceEvaluateList,
  addServiceEvaluateRecord,
} from "./api.js";
import cRate from "./components/c-rate.vue";

export default {
  name: "service-detail",
  components: {
    rate,
    cRate,
  },
  props: {},
  data() {
    return {
      isShow: true,
      score: 0,
      finishedText: "没有更多了",
      finished: false,
      serviceId: "", //便民服务id
      evaluateParams: {
        score: 0, // 评价分数
        commentContent: "", //评价内容
      },
      isShowDialog: false, //评价弹窗
      visibleItems: 2,
      detailParams: {}, //详情数据
      requestData: {
        curPage: 1,
        pageSize: 10,
      },
      evaluateList: [],
    };
  },
  computed: {
    ...mapState(["userId", "tenantId", "communityId", "houseId"]),
  },
  created() {
    const { id } = this.$route.query;
    if (id != undefined) {
      this.serviceId = id;
      this.getServiceInfoById(id);
    }
    this.initWxConfig();
  },
  mounted() {},
  methods: {
    handelClickImgView(url) {
      ImagePreview([url]);
    },
    contact() {
      window.location.href = `tel://${this.detailParams.contactMobile}`;
    },
    confirm() {
      this.isShow = false;
    },
    //时间差计算
    checkTime(time) {
      const currentTime = new Date();
      const fixedTime = new Date(time);
      const timeDifference = currentTime - fixedTime;
      const minutesDifference = Math.floor(timeDifference / 60000);
      // 判断当前时间是否在十五分钟前
      if (minutesDifference <= 15 && minutesDifference >= -15) {
        return "刚刚";
      } else {
        // 格式化固定时间
        // const formattedTime = fixedTime.toLocaleString(); // 格式为默认的日期时间格式
        return time;
      }
    },
    init() {
      this.requestData.curPage = 1;
      this.onLoad();
    },
    onLoad() {
      this.getServiceEvaluateList();
    },
    // 获取评论列表
    getServiceEvaluateList() {
      let params = {
        serviceId: this.serviceId,
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
      };
      this.$axios
        .get(
          `${getServiceEvaluateList}`,
          { params },
          {
            headers: { "Content-Type": "application/json" },
          }
        )
        .then((res) => {
          if (res.code === 200) {
            if (this.requestData.curPage >= res.data.pages) {
              this.finished = true;
            } else {
              this.finished = false;
              this.$refs.list.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.evaluateList = [];
              this.finishedText = "";
              return;
            }
            if (this.requestData.curPage === 1) {
              this.evaluateList = res.data.records;
            } else {
              res.data.records.forEach((item) => {
                this.evaluateList.push(item);
              });
            }

            this.evaluateList.forEach((item) => {
              if (item.evaluaterAvatar && item.evaluaterAvatar.length > 0) {
                item.evaluaterAvatar = this.$handleImg(
                  92,
                  92,
                  item.evaluaterAvatar
                );
              }
            });
            this.requestData.curPage++;
          }
        });
    },
    //发布评价
    publishEvaluate() {
      if (this.score == 0) {
        this.evaluateParams.score = 5;
      } else {
        this.evaluateParams.score = this.score;
      }
      const params = {
        ...this.evaluateParams,
        optUser: this.userId,
        serviceId: this.serviceId,
      };
      this.$axios.post(addServiceEvaluateRecord, params).then((res) => {
        if (res.code == 200) {
          this.$toast("评价成功");
          this.isShowDialog = false;
          this.init();
          this.getServiceInfoById(this.serviceId);
        }
      });
    },
    //获取详情
    getServiceInfoById(id) {
      this.$axios.get(`${getServiceInfoById}?id=${id}`).then((res) => {
        if (res.code == 200) {
          this.detailParams = res.data;
        }
      });
    },
    // 微信授权H5可调用的 wx API功能
    async initWxConfig() {
      let ourl;
      if (process.env.NODE_ENV == "production") {
        ourl = location.href.split("#")[0];
      } else {
        ourl = origin;
      }
      let params = {
        url: ourl,
      };
      let url = initWxJsSdkUrl;
      let res = await this.$axios.post(`${url}`, this.$qs.stringify(params));
      if (res.code == 200) {
        this.wxData = res.data;
        console.log("this.wxData ----->", this.wxData);
        wx.config({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId: this.wxData.appId, // 必填，公众号的唯一标识
          timestamp: this.wxData.timestamp, // 必填，生成签名的时间戳
          nonceStr: this.wxData.nonceStr, // 必填，生成签名的随机串
          signature: this.wxData.signature, // 必填，签名
          jsApiList: ["checkJsApi", "openLocation"],
        });
      }
    },
    goNavigation(detailParams, latitude, longitude) {
      const addressDetail = `${detailParams.address}${detailParams.doorplate}`;
      wx.ready(() => {
        console.log(" wx.ready----->");
        wx.checkJsApi({
          jsApiList: ["openLocation"],
          success: (rest) => {
            //打开指定位置
            wx.openLocation({
              type: "gcj02",
              latitude: Number(latitude),
              longitude: Number(longitude),
              name: addressDetail, // 位置名
              address: addressDetail, // 地址详情说明
              scale: 18, // 地图缩放级别,整型值,范围从1~28。默认为最大
            });
          },
          fail: (res) => {
            console.log("res----->", res);
          },
        });
      });
      wx.error((error) => {
        console.log("error----->", error);
      });
    },

    onFocus() {},
    goEvaluate() {
      this.score = 0;
      this.evaluateParams = {
        score: 0, // 评价分数
        commentContent: "", //评价内容
      };
      this.isShowDialog = true;
    },
    handleChange(value) {
      console.log("value", value);
    },
  },
};
</script>

<style scoped lang="less">
.service-detail {
  box-sizing: border-box;
  background: #ffffff;
  width: 100%;
  min-height: 100vh;
  padding: 22px 30px 0 30px;
  padding-bottom: calc(100px + constant(safe-area-inset-bottom));
  padding-bottom: calc(100px + env(safe-area-inset-bottom));
  .dialog {
    z-index: 999;
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    .dialog-content {
      width: 100%;
      height: 80%;
      background: #ffffff;
      border-radius: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      box-sizing: border-box;
      padding: 42px 34px 52px 34px;
      .title {
        text-align: center;
        font-size: 30px;
        font-weight: 300;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
        margin-bottom: 32px;
      }
      .text {
        flex: 1;
        overflow-y: scroll;
        font-size: 26px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 36px;
      }
      .footer {
        text-align: center;
        width: 550px;
        height: 70px;
        margin: 52px auto auto;
        line-height: 70px;
        background: #db431d;
        border-radius: 16px;
        font-size: 32px;
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
  .service-info {
    display: flex;
    align-items: stretch;
    box-sizing: border-box;
    margin-bottom: 58px;
    .service-info-l {
      height: 220px;
      width: 224px;
      overflow: hidden;
      border-radius: 16px;
      margin-right: 22px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .service-info-r {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      max-width: calc(100% - 246px);
      box-sizing: border-box;
      padding: 20px 0 8px 0;
      .service-info-r-title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          font-size: 32px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 44px;
        }
        .detail-r-evaluate {
          .detail-r-evaluate-status {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 110px;
            height: 38px;
            border-radius: 19px;
            border: 2px solid #ffd343;
            font-size: 24px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffd343;
            line-height: 34px;
          }
          .rate {
            width: 206px;
            overflow: hidden;
            position: relative;
            height: 32px;
          }
        }
      }
      .service-content {
        box-sizing: border-box;
        margin: 10px 0 20px 0;
        .service-describe {
          font-size: 28px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.65);
          line-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-block: 10px;
        }
        .service-issuer {
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #4864b0;
          line-height: 34px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      .service-status {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        .time {
          white-space: nowrap;
          text-align: left;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.25);
          line-height: 34px;
        }
        .type {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          text-align: right;
          font-size: 24px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.5);
          line-height: 34px;
        }
      }
    }
  }
  .service-introduce {
    width: 100%;
    box-sizing: border-box;
    .service-introduce-info {
      margin-bottom: 48px;
    }
    .common-title {
      font-size: 30px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: rgba(0, 0, 0, 0.85);
      line-height: 42px;
      margin-bottom: 20px;
    }
    .service-introduce-content {
      display: flex;
      align-items: center;
      font-size: 28px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #6d6d6d;
      line-height: 40px;
      min-height: 80px;
      background: #fafafa;
      border-radius: 8px;
      padding: 0 26px 0 36px;
      box-sizing: border-box;
    }
    .address {
      display: flex;
      justify-content: space-between;
      align-items: center;
      img {
        width: 46px;
        height: 42px;
      }
    }
    .describe {
      height: auto;
      padding-top: 20px;
      padding-bottom: 20px;
      align-items: stretch;
      min-height: 168px;
      ::v-deep .v-h5Mtml {
        .content {
          padding: 0;
        }
      }
    }
    .service-type {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 30px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 42px;
      &.time {
        margin-bottom: 48px;
      }
    }
    .line {
      margin: 34px 0 36px 0;
      width: 100%;
      height: 2px;
      background-color: rgba(106, 106, 106, 0.1);
    }
  }
  .service-evaluate {
    width: 100%;
    .service-evaluate-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 28px;
      span {
        font-size: 30px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 42px;
      }
      div {
        width: 122px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        border: 2px solid #3680ff;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #3680ff;
        line-height: 36px;
      }
    }
    .service-evaluate-list {
      width: 100%;
      box-sizing: border-box;
      &:not(:last-child) {
        margin-bottom: 40px;
      }
      .evaluate-user-detail {
        padding-left: 18px;
        display: flex;
        align-items: stretch;
        box-sizing: border-box;
        .user-avatar {
          width: 92px;
          height: 92px;
          margin-right: 22px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 50%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .user-info {
          flex: 1;
          box-sizing: border-box;
          .username {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .name {
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 36px;
            }
            .time {
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.25);
              line-height: 36px;
            }
          }
          .rate {
            display: flex;
            align-items: center;
            .rating {
              ::v-deep .van-rate__item {
                &:not(:last-child) {
                  padding-right: 12px !important;
                }
                .van-icon {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 32px;
                  height: 30px;
                }
                .van-icon__image {
                  width: 30px;
                  height: 30px;
                }
              }
            }
            .status {
              margin-left: 22px;
              font-size: 26px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: 36px;
            }
          }
        }
      }
      .evaluate-content {
        box-sizing: border-box;
        padding-left: 18px;
        margin-top: 12px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        word-break: break-all;
      }
      .line {
        margin-top: 38px;
        width: 100%;
        height: 2px;
        background-color: rgba(0, 0, 0, 0.06);
      }
    }
    .expand {
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #bebebe;
      line-height: 36px;
    }
  }
  .evaluate-dialog {
    ::v-deep .van-popup {
      height: 520px !important;
      box-sizing: border-box;
      padding: 30px 34px 76px 36px;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.58);
      border-radius: 16px 16px 0 0;
      overflow-y: hidden;
    }
    // @keyframes slide-top {
    //   0% {
    //     -webkit-transform: translateY(100px);
    //     opacity: 0;
    //     // transform: translateY(1000px);
    //   }
    //   100% {
    //     -webkit-transform: translateY(0);
    //     opacity: 1;
    //     // transform: translateY(0);
    //   }
    // }

    .dialog-content {
      // position: absolute;
      // left: 0;
      // bottom: 0;
      // width: 100%;
      // box-sizing: border-box;
      // padding: 30px 34px 76px 36px;
      // background: #ffffff;
      // box-shadow: 0px 4px 16px 0px rgba(225, 225, 225, 0.58);
      // border-radius: 16px 16px 0 0;
      &.active {
        animation: slide-top 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      }

      .rate {
        display: flex;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        > div {
          font-size: 26px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 36px;
        }
        .rating {
          ::v-deep .van-rate__item {
            &:not(:last-child) {
              padding-right: 12px !important;
            }
            .van-icon__image {
              width: 30px;
              height: 30px;
            }
          }
        }
      }
      .send-content {
        width: 100%;
        height: 260px;
        margin: 28px 0 22px 0;
        box-sizing: border-box;
        overflow: hidden;
        border-radius: 16px;
        > div {
          width: 100%;
          height: 100%;
        }
        ::v-deep .van-cell {
          width: 100%;
          height: 100%;

          background: rgba(0, 0, 0, 0.05);
        }
        ::v-deep .van-field__body {
          width: 100%;
          height: 100%;
          textarea {
            width: 100%;
            height: 100% !important;
          }
        }
      }
      .send-btn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        box-sizing: border-box;
        .btn {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 210px;
          height: 70px;
          background: #3781ff;
          border-radius: 16px;
          font-size: 30px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 42px;
        }
      }
    }
  }
}
</style>
